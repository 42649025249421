import { XF } from "./XF";

let imageUpload: HTMLInputElement | null;
let imagePlaceholder: HTMLDivElement | null;
let imageContainer: HTMLDivElement | null;
let imagePreview: HTMLImageElement | null;
let imagesContainer: HTMLDivElement | null;
let nonEmptyImagesContainer: HTMLDivElement | null;
let fileUploads: File[];
let deletedAttachmentsIds: string[];
let maxImageCount: number;
let numberOfImages: number;
let uploadImagesButton: HTMLButtonElement | null;
let uploadErrorContainer: HTMLDivElement | null;
let productForm: HTMLFormElement | null;
let imageTiles: NodeListOf<HTMLDivElement>;
let mainAttachmentIdInput: HTMLInputElement | null;
let mainAttachmentFilenameInput: HTMLInputElement | null;

export function bindProductFormEvents(modal: HTMLDivElement) {
  const { poMultipleImages, maxProductImageCount } = window as unknown as {
    poMultipleImages: boolean;
    maxProductImageCount: number;
  };

  if (poMultipleImages) {
    maxImageCount = maxProductImageCount || 25;

    productForm = modal.querySelector<HTMLFormElement>(
      ".js-product-ownership-form"
    );
    productForm?.addEventListener("submit", () => {
      assignFileUploadsToInput();
      assignDeletedAttachmentsIdsToInput();
    });
  }

  bindImageUploadEvents(<HTMLDivElement>modal, poMultipleImages);

  const saveButton = modal.querySelector<HTMLButtonElement>(
    ".save-product-button"
  );
  saveButton?.addEventListener("click", () => {
    saveButton.classList.add("is-disabled");
  });

  document.querySelectorAll<HTMLInputElement>(".input").forEach((input) => {
    input.addEventListener("invalid", () => {
      saveButton?.classList.remove("is-disabled");
    });
  });

  const yearInput =
    document.querySelector<HTMLInputElement>("input[name='year']");
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 10;
  yearInput?.setAttribute("max", maxYear.toString());
  const minYear = currentYear - 200;
  yearInput?.setAttribute("min", minYear.toString());
}

function bindImageUploadEvents(
  element: HTMLDivElement,
  poMultipleImages: boolean
) {
  if (!element) {
    return;
  }

  imageUpload = element.querySelector<HTMLInputElement>(
    ".product-image-uploader"
  );
  imagePlaceholder = element.querySelector<HTMLDivElement>(
    ".product-form-image-placeholder"
  );

  if (poMultipleImages) {
    populateMultiImageManipulatorVariables(element);
  } else {
    populateSingleImageManipulatorVariables(element);
  }

  document.querySelectorAll(".upload-product-image").forEach((button) => {
    if (button.getAttribute("listener-attached") !== "true") {
      button.addEventListener("click", () => {
        button.setAttribute("listener-attached", "true");
        imageUpload?.click();
      });
    }
  });

  if (poMultipleImages) {
    bindUploadImagesEventToMultiImageManipulator(element);
    bindEventsToImageTiles(element);
    if (!mainAttachmentIdInput?.value || mainAttachmentIdInput.value === "0") {
      setMainTagOnFirstTile();
    }
  } else {
    bindUploadImageEventForSingeImageManipulator();
    bindDeleteEventToSingleImageManipulator(element);
  }
}

function populateSingleImageManipulatorVariables(element: HTMLDivElement) {
  imagePreview = element.querySelector<HTMLImageElement>(
    ".product-image-preview"
  );
  imageContainer = element.querySelector<HTMLDivElement>(
    ".product-form-image-container"
  );
}

function populateMultiImageManipulatorVariables(element: HTMLDivElement) {
  nonEmptyImagesContainer = element.querySelector<HTMLDivElement>(
    ".non-empty-images-container"
  );

  imagesContainer = element.querySelector<HTMLDivElement>(
    ".product-form-attachments"
  );

  uploadImagesButton = element.querySelector<HTMLButtonElement>(
    ".upload-images-button"
  );

  uploadErrorContainer = element.querySelector<HTMLDivElement>(
    "#upload-multi-image-error"
  );

  mainAttachmentIdInput = element.querySelector<HTMLInputElement>(
    ".js-main-attachment-id"
  );

  mainAttachmentFilenameInput = element.querySelector<HTMLInputElement>(
    ".js-main-attachment-filename"
  );

  fileUploads = [];
  deletedAttachmentsIds = [];
}

function bindUploadImageEventForSingeImageManipulator() {
  imageUpload?.addEventListener("change", () => {
    if (imageUpload?.files && imageUpload.files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        imagePlaceholder?.classList.add("hidden");
        imageContainer?.classList.remove("hidden");
        imagePreview?.setAttribute("src", <string>reader.result);
      };

      reader.readAsDataURL(imageUpload.files[0]);
    }
  });
}

function bindUploadImagesEventToMultiImageManipulator(element: HTMLDivElement) {
  imageUpload?.addEventListener("change", () => {
    if (imageUpload?.files?.length && imageUpload?.files?.length > 0) {
      imagePlaceholder?.classList.add("hidden");
      nonEmptyImagesContainer?.classList.remove("hidden");

      for (let i = 0; i < imageUpload?.files.length; i++) {
        const file = imageUpload?.files.item(i);
        if (!file) {
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          processFileUpload(file, <string>reader.result);
        };

        reader.onloadend = () => {
          bindEventsToImageTiles(element);
        };

        reader.readAsDataURL(file);
      }
    }
  });
}

function processFileUpload(file: File, imgSrc: string) {
  let error = "";
  if (numberOfImages + 1 > maxImageCount) {
    error = `You can not upload more than ${maxImageCount} images`;
  }
  if (numberOfImages + 1 >= maxImageCount) {
    if (uploadImagesButton) uploadImagesButton.disabled = true;
  }
  const maxUploadSize = XF.config.uploadMaxFilesize || 0;
  if (maxUploadSize > 0 && file.size > maxUploadSize) {
    const maxFileSize = Math.floor(XF.config.uploadMaxFilesize! / 1024 / 1024);
    error = `Your files uploaded exceed the maximum limit of ${maxFileSize}MB.`;
  }
  if (error) {
    if (uploadErrorContainer) uploadErrorContainer.innerHTML = error;
    return;
  }
  if (uploadErrorContainer) uploadErrorContainer.innerHTML = "";

  fileUploads.push(file);
  numberOfImages++;
  addImagePreviewTile(imgSrc, file.name);
}

function addImagePreviewTile(imageSrc: string, filename: string) {
  const tileHtml = `<div class="product-image-tile" data-upload-filename="${filename}">
                    <img class="product-image-preview" alt="Vehicle image" src="${imageSrc}" class="lazyload" />
                    <button type="button" class="button--link delete-product-image button button--icon-only">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="california-icon" width="1.5rem" height="1.5rem" role="graphics-symbol" aria-label="Close"><path fill="currentColor" d="M17.736 7.539a.9.9 0 0 0-1.27-1.27l-4.462 4.46-4.465-4.465a.9.9 0 0 0-1.27 1.27l4.46 4.462-4.465 4.465a.9.9 0 0 0 1.27 1.27l4.462-4.46 4.465 4.465a.9.9 0 0 0 1.27-1.27l-4.46-4.462z"></path></svg>
                    </button>
                </div>`;
  imagesContainer!.innerHTML += tileHtml;
  return tileHtml;
}

function bindDeleteEventToSingleImageManipulator(element: HTMLDivElement) {
  const deleteSingleImageButton = element.querySelector<HTMLButtonElement>(
    ".delete-product-image"
  );
  const deletePreviousImageHiddenInput =
    element.querySelector<HTMLInputElement>(
      "input[name='delete-previous-image']"
    );
  deleteSingleImageButton?.addEventListener("click", () => {
    imageUpload!.value = "";
    imagePlaceholder?.classList.remove("hidden");
    imageContainer?.classList.add("hidden");
    imagePreview?.setAttribute("src", "#");
    deletePreviousImageHiddenInput!.value = "1";
  });
}

function bindEventsToImageTiles(element: HTMLDivElement) {
  imageTiles = element.querySelectorAll<HTMLDivElement>(".product-image-tile");
  numberOfImages = imageTiles.length;
  imageTiles?.forEach((imageTile) => {
    bindDeleteEventToImageTile(imageTile);
    bindSetMainToImageTile(imageTile);
  });
}

function bindDeleteEventToImageTile(imageTile: HTMLDivElement) {
  const deleteButton = imageTile.querySelector(".delete-product-image");
  deleteButton?.addEventListener("click", (event) => {
    event.stopPropagation();
    const { uploadFilename, attachmentId } = imageTile.dataset;
    if (uploadFilename) {
      const uploadIndex = fileUploads.findIndex(
        (file) => file.name === uploadFilename
      );
      fileUploads.splice(uploadIndex, 1);
    }
    if (attachmentId) {
      deletedAttachmentsIds.push(attachmentId);
    }

    imageTile.remove();

    if (
      uploadFilename &&
      mainAttachmentFilenameInput?.value === uploadFilename
    ) {
      setMainTagOnFirstTile();
    } else if (attachmentId && mainAttachmentIdInput?.value === attachmentId) {
      setMainTagOnFirstTile();
    }
    if (uploadImagesButton) uploadImagesButton.disabled = false;
    if (uploadErrorContainer) uploadErrorContainer.innerHTML = "";

    numberOfImages--;
    if (numberOfImages === 0) {
      imagePlaceholder?.classList.remove("hidden");
      nonEmptyImagesContainer?.classList.add("hidden");
      if (mainAttachmentFilenameInput) mainAttachmentFilenameInput.value = "";
      if (mainAttachmentIdInput) mainAttachmentIdInput.value = "";
    }
  });
}

function bindSetMainToImageTile(imageTile: HTMLDivElement) {
  if (!mainAttachmentIdInput?.value && !mainAttachmentFilenameInput?.value) {
    setMainTagOnImageTile(imageTile);
  }
  imageTile.addEventListener("click", () => {
    clearMainTagFromTiles();
    setMainTagOnImageTile(imageTile);
  });
}

function clearMainTagFromTiles() {
  imageTiles?.forEach((imageTile) => {
    imageTile.classList.remove("main");
  });
}

function setMainTagOnFirstTile() {
  const firstTile = productForm?.querySelector<HTMLDivElement>(
    ".product-image-tile"
  );
  if (firstTile) setMainTagOnImageTile(firstTile);
}

function setMainTagOnImageTile(imageTile: HTMLDivElement) {
  imageTile.classList.add("main");
  const { uploadFilename, attachmentId } = imageTile.dataset;
  if (attachmentId) {
    if (mainAttachmentIdInput) mainAttachmentIdInput.value = attachmentId;
  }

  if (uploadFilename) {
    if (mainAttachmentFilenameInput)
      mainAttachmentFilenameInput.value = uploadFilename;
  }
}

function assignFileUploadsToInput() {
  if (!imageUpload) return;

  const dataTransfer = new DataTransfer();
  for (let i = 0, len = fileUploads.length; i < len; i++) {
    dataTransfer.items.add(fileUploads[i]);
  }
  imageUpload.files = dataTransfer.files;
}

function assignDeletedAttachmentsIdsToInput() {
  const deletedAttachmentsIdsInput =
    productForm?.querySelector<HTMLInputElement>(".js-deleted-attachment-ids");
  if (!deletedAttachmentsIdsInput) return;

  deletedAttachmentsIdsInput.value = deletedAttachmentsIds.toString();
}
